<template>
    <div style="padding-bottom:0px;padding-top:0px;">

        <v-row class="d-flex">
            <v-col >
                <v-row class="d-flex justify-center">
                    <v-col cols="12"
                           sm="8"
                           md="10"
                           lg="10"
                           class="mx-auto">
                        <v-card flat
                                class="bg-gradient">
                                <app-header :details="cardDetail"></app-header>
                            <v-row>
                                <!-- <v-card class="font-weight-bold mt-5 overflow-hidden"> -->
                                <v-card-text>
                                    <p class="text-sm font-weight-semibold mt-5 text-black text-center pt-5"
                                       style="margin-bottom: 0;">
                                        {{
                                            $t('Customer Service')
                                        }}
                                    </p>

                                    <v-row class="justify-center">
                                        <v-col cols="12"
                                               lg="9"
                                               md="9"
                                               sm="9" class="mx-auto">

                                            <v-card class="mt-8 px-5 balance-card-background">
                                                <v-card-text class="pa-5 px-1">
                                                    <span class="text-sm font-weight-semibold text-black px-2">
                                                        {{ $t('Settings') }}
                                                    </span>

                                                    <v-row class="mt-2 pa-1">
                                                        <v-col cols="12"
                                                               class="pl">
                                                            <v-row class="">
                                                                <v-col lg="4"
                                                                       md="6"
                                                                       sm="6">
                                                                    <div class="d-flex justify-start">
                                                                        <div class="d-flex px-2">
                                                                            <v-img contain
                                                                                   style="height: 18px;  top: 3px;"
                                                                                   class="auth-3d-group overflow-hidden"
                                                                                   :src="require(`@/assets/images/logos/detail-logo.png`)">
                                                                            </v-img>
                                                                        </div>
                                                                        <span
                                                                        style="font-size: 12px;" class="text-black">
                                                                            {{ $t('Update Personal Details') }}
                                                                        </span>
                                                                    </div>
                                                                </v-col>

                                                                <v-col lg="8"
                                                                       md="6"
                                                                       sm="6" style="padding-left:0;">
                                                                    <div class="d-flex">
                                                                            <div class="d-flex px-2">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/detail-logo.png`)">
                                                                                </v-img>
                                                                            </div>
                                                                            <span
                                                                            style="font-size: 12px;"  class="text-black">
                                                                                {{ $t('Update Contact Information') }}
                                                                            </span>
                                                                    </div>
                                                                </v-col>

                                                                <v-col lg="4"
                                                                       md="6"
                                                                       sm="6">

                                                                    <div class="d-flex">
                                                                            <div class="d-flex px-2">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/detail-logo.png`)">
                                                                                </v-img>
                                                                            </div>
                                                                            <span
                                                                            style="font-size: 12px;"  class="text-black">
                                                                                {{ $t('Marketing Communication') }}
                                                                            </span>
                                                                    </div>
                                                                </v-col>

                                                                <v-col lg="4"
                                                                       md="6"
                                                                       sm="6" style="padding-left:0;">

                                                                    <div class="d-flex">
                                                                            <div class="d-flex px-2">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/detail-logo.png`)">
                                                                                </v-img>
                                                                            </div>
                                                                            <span
                                                                            style="font-size: 12px;"  class="text-black">
                                                                                {{ $t('Communication Language') }}
                                                                            </span>
                                                                    </div>
                                                                </v-col>
                                                                <v-col lg="4"
                                                                       md="6"
                                                                       sm="6">
                                                                    <div class="d-flex">
                                                                            <div class="d-flex px-2">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/detail-logo.png`)">
                                                                                </v-img>
                                                                            </div>
                                                                            <span
                                                                            style="font-size: 12px;" class="text-black">
                                                                                {{ $t('Login Details') }}
                                                                            </span>
                                                                    </div>
                                                                </v-col>
                                                            </v-row>

                                                            <v-row>
                                                                <v-col lg="12"
                                                                       md="6"
                                                                       sm="6"
                                                                       class="mt-8">
                                                                    <span
                                                                          class="text-sm text-black font-weight-bold px-3">
                                                                        {{ $t('Service Request') }}
                                                                    </span>
                                                                </v-col>
                                                                <v-card-text class="d-flex justify-start px-1">
                                                                    <v-col lg="4"
                                                                           md="6"
                                                                           sm="6">
                                                                        <div class="d-flex">
                                                                            <div class="d-flex">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/Ellipse 258.png`)">
                                                                                    <v-img contain
                                                                                           style="height: 15px;"
                                                                                           class="auth-3d-group overflow-hidden"
                                                                                           :src="require(`@/assets/images/logos/Vector (6).png`)">
                                                                                    </v-img>
                                                                                </v-img>

                                                                                <div class="d-flex">
                                                                                    <span
                                                                                    style="font-size: 12px;"  class="text-black">
                                                                                        {{
                                                                                            $t('Update Personal Details')
                                                                                        }}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col lg="8"
                                                                           md="6"
                                                                           sm="6"  style="padding-left:0;">
                                                                        <div class="d-flex">
                                                                            <div class="d-flex">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/Ellipse 258.png`)">
                                                                                    <v-img contain
                                                                                           style="height: 15px;"
                                                                                           class="auth-3d-group overflow-hidden"
                                                                                           :src="require(`@/assets/images/logos/Vector (6).png`)">
                                                                                    </v-img>
                                                                                </v-img>
                                                                                <div class="d-flex">
                                                                                    <span
                                                                                    style="font-size: 12px;" class="text-black">
                                                                                        {{
                                                                                            $t('Update Personal Details')
                                                                                        }}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </v-col>
                                                                </v-card-text>
                                                            </v-row>

                                                            <v-row>
                                                                <v-col lg="12"
                                                                       md="6"
                                                                       sm="6"
                                                                       class="mt-5">
                                                                    <span
                                                                          class="text-sm text-black font-weight-bold px-3">
                                                                        {{ $t('FAQs') }}
                                                                    </span>
                                                                </v-col>
                                                                <v-card-text class="d-flex px-1">
                                                                    <v-col lg="4"
                                                                           md="4"
                                                                           sm="6" style="padding-bottom:0px;padding-top:0px;"
                                                                           class="mt-3">
                                                                        <div class="d-flex">
                                                                            <div class="d-flex px-2">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/detail-logo.png`)">
                                                                                </v-img>
                                                                            </div>
                                                                            <span
                                                                            style="font-size: 12px;" class="text-black">
                                                                                {{ $t('I don’t receive my transaction alerts?') }}
                                                                            </span>
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col lg="3"
                                                                           md="4"
                                                                           sm="6">
                                                                        <div class="d-flex">
                                                                            <div class="d-flex px-2">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/detail-logo.png`)">
                                                                                </v-img>
                                                                            </div>

                                                                            <span
                                                                                  class="text-black" style="font-size: 12px;">
                                                                                {{ $t('How to pay my Bill?') }}
                                                                        </span>
                                                                        </div>
                                                                    </v-col>
                                                                    <v-col lg="4"
                                                                           md="4"
                                                                           sm="6">
                                                                        <div class="d-flex">
                                                                            <div class="d-flex px-2">
                                                                                <v-img contain
                                                                                       style="height: 18px;  top: 3px;"
                                                                                       class="auth-3d-group overflow-hidden"
                                                                                       :src="require(`@/assets/images/logos/detail-logo.png`)">
                                                                                </v-img>
                                                                            </div>
                                                                            <span
                                                                            style="font-size: 12px;"  class="text-black">
                                                                                {{ $t('How to update my mobile number?') }}
                                                                            </span>
                                                                        </div>
                                                                    </v-col>
                                                                </v-card-text>
                                                            </v-row>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>



                                </v-card-text>
                                <!-- </v-card> -->
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <!-- </div> -->
            </v-col>


        </v-row>
    </div>
</template>

<script>
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import AppHeader from '../test/AppHeader.vue'
import sharedServices from '@/services/shared-services'
import enrollmentServices from '@/services/enrollment-services'


export default {
    components: {
        AppBarI18n,
        AppBarThemeSwitcher,
        AppHeader
    },
    data: () => ({
        valid: false,
        // First Form
        legalId: '', // From User Input
        captchaValue: '', // From UserInput
        captchaId: '', // From API

        // Second Form
        otp: '', // From USer Input

        disableVerify: true,
        captchaImage: '', // From API to Display Captcha Image
        genericRules: [
            v => !!v || 'Field is required'
        ],
        cardDetail: null
    }),
    mounted()
    {
        
        this.requestCaptcha();
    },
    methods: {

        submit()
        {
            this.$emit('submit')
        },



    },

}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';
</style>
